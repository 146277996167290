import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { AddCircleOutline, AddCircle } from "@material-ui/icons";
import { useStore } from "../../store/Store";
import { appLoading, error, addHistoricList } from "../../store/StoreActions";
import { QUERY_CRITERIA, DOWNLOAD_FILE } from "../../config/ApiUrls";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableContainer from "@material-ui/core/TableContainer";
import {
  StyledTableBody,
  StyledTableRow,
} from "../../modules/Views/Query/QueryNListView/ListView/components/ListViewContent/index.css";
import EllipisWithTooltip from "../EllipsisWithToolTip";
import { getComparator, stableSort } from "../Table/helpers/Sorting";
import EnhancedTableHead from "../Table/components/EnhancedTableHead";
import CancelIcon from "@material-ui/icons/Cancel";
import "./index.css";
import { headCellsModal } from "../../modules/Views/Query/QueryNListView/ListView/components/ListViewContent/constants";
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    maxWidth: "95%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStylesScroll = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 430,
  },
});
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1300,
    backgroundColor: "#d3f3f7",

    border: "2px solid #a5e6ee",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const classesScroll = useStylesScroll();
  const { isin } = props;
  const [globalState, dispatch] = useStore();
  const [list, setList] = React.useState(globalState?.historicList);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [icon, setIcon] = React.useState(false);

  const [orderBy, setOrderBy] = React.useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  React.useEffect(() => {
    if (globalState.historicList) {
      setList(globalState.historicList);
    }
  }, [globalState.historicList]);
  const handleOpen = (event) => {
    setIcon(true);
    if (isin) {
      dispatch(appLoading(true));

      let reqBody = {
        continuationToken: "",
        inputPublicNode: {
          isin: isin,
          historicVersion: true,
        },
      };
      axios
        .post(QUERY_CRITERIA, reqBody, {
          params: {
            page: 0,
            limit: 50,
          },
        })
        .then((res) => {
          res?.data?.publicnode?.sort(
            (a, b) => parseFloat(b.pnVersionNo) - parseFloat(a.pnVersionNo)
          );
          dispatch(addHistoricList(res));
          setOpen(true);

          dispatch(appLoading(false));
        })
        .catch((err) => {
          dispatch(error());
          dispatch(appLoading(false));
        });
    }
  };

  const handleClose = () => {
    setIcon(false);
    setOpen(false);
    dispatch(addHistoricList([]));
  };
  const getFile = (parameter, generationId) => (event) => {
    const generationIdExt = `&generationId=${generationId ? generationId : ""}`;

    const historicVersionExt = `&historicVersion=true`;
    window.open(
      DOWNLOAD_FILE + parameter + generationIdExt + historicVersionExt,
      "_blank"
    );
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <CancelIcon
        style={{
          position: "absolute",
          top: "8px",
          right: "16px",
          fontSize: "24px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      />

      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h2"
        id="tableTitle"
        component="div"
        style={{ height: "30px" }}
      >
        Versions [{list?.length}]
      </Typography>

      <Paper className={classesScroll.root}>
        <TableContainer className={classesScroll.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              rowCount={list.length}
              onRequestSort={handleRequestSort}
              orderBy={orderBy}
              order={order}
              headCells={headCellsModal}
            />

            <StyledTableBody>
              {stableSort(list, getComparator(order, orderBy)).map(
                (listItem, index) => {
                  return (
                    <StyledTableRow>
                      <TableCell id="isin" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.isin || "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <TableCell id="pnVersionNo" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.pnVersionNo || "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <TableCell id="productName" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.productName || "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <TableCell id="issuerName" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.issuerName || "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <TableCell id="lastUpdateDate" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.lastUpdateDate
                            ? moment
                                .utc(listItem?.lastUpdateDate)
                                .tz("Europe/Berlin")
                                .format("DD/MM/YYYY HH:mm:ss")
                            : "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <TableCell id="issuerLEI" align="left">
                        <EllipisWithTooltip className="overflow-td">
                          {listItem?.issuerLEI || "-"}
                        </EllipisWithTooltip>
                      </TableCell>
                      <td
                        id="t&c"
                        align="center"
                        style={{ border: "1px solid #a5e6ee" }}
                      >
                        <GetAppIcon
                          onClick={getFile(listItem?.isin, listItem?.generationId)}
                          className="download"
                          aria-hidden="true"
                        />
                      </td>
                    </StyledTableRow>
                  );
                }
              )}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div>
      {icon ? (
        <AddCircle
          style={{
            color: "#52c3d6",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        />
      ) : (
        <AddCircleOutline
          style={{
            color: "#52c3d6",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
