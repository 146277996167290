import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import FormView from "../../../../../../../../../components/FormView";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { StyledSearchCriteria } from "./StyledSearchCriteria";
import OperatorMenu from "./components/OperatorMenu";
import { useStore } from "../../../../../../../../../store/Store";
import { DEFAULT_VALUES, ERROR_DEFAULT_VALUES } from "./constants";
import {
  addQuery,
  toggleExpandView,
  addList,
  error,
  queryFired,
  queryBuild,
  appLoading,
} from "../../../../../../../../../store/StoreActions";
import axios from "axios";
import { queryBuilder } from "../../../../../../../../../components/Helpers/QueryNList";
import { QUERY_CRITERIA } from "../../../../../../../../../config/ApiUrls";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import calendarIcon from "../../../../../../../../../assets/calendar-icon.svg";
import moment from "moment";
function SearchCriteria() {
  const [globalState, dispatch] = useStore();
  const dateFormat = "YYYY-MM-DD";
  const dateFormatDisplay = "DD/MM/YYYY";
  const [query, setQuery] = React.useState(globalState.queries);
  const [displayError, setDisplayError] = React.useState(ERROR_DEFAULT_VALUES);

  const setOperator = (id, operator) => {
    if (operator === "[]") {
      setQuery({ ...query, [id]: { ...query[id], value: null, operator } });
    } else {
      setQuery({
        ...query,
        [id]: { ...query[id], range: { from: null, to: null }, operator },
      });
    }
  };

  const setErrorMessage = (id, value) => {
    const { length, pattern } = displayError[id];
    const regExp = new RegExp(pattern);
    if (value.length <= length && regExp.test(value)) {
      if (displayError[id].error) {
        setDisplayError({
          ...displayError,
          [id]: {
            ...displayError[id],
            error: false,
            helperText: "",
          },
        });
      }
      return false;
    }
    if (value.length > length) {
      setDisplayError({
        ...displayError,
        [id]: {
          ...displayError[id],
          error: true,
          helperText: `length should be ${length}`,
        },
      });
      return true;
    }
    if (pattern === "^[ A-Za-z0-9+.:=_/\u00C0-\u017F.\\-]*$") {
      setDisplayError({
        ...displayError,
        [id]: {
          ...displayError[id],
          error: true,
          helperText: `Only alphanumeric, space and these characters +.:=_/-  allowed`,
        },
      });
      return true;
    }
    if (pattern === "^[a-zA-Z0-9]*$") {
      setDisplayError({
        ...displayError,
        [id]: {
          ...displayError[id],
          error: true,
          helperText: `Only alphanumeric allowed`,
        },
      });
      return true;
    }
    if (pattern === "^[0-9]*$") {
      setDisplayError({
        ...displayError,
        [id]: {
          ...displayError[id],
          error: true,
          helperText: `Only Numeric allowed`,
        },
      });
      return true;
    }
    return true;
  };
  const changeFieldValue = (event) => {
    const id = [event.target.id];
    const value = event.target.value;
    if (!displayError[id] || !setErrorMessage(id, value)) {
      let name = event.target.name;

      if (name === "from" || name === "to") {
        setQuery({
          ...query,
          [id]: { ...query[id], range: { ...query[id]?.range, [name]: value } },
        });
      } else {
        setQuery({ ...query, [id]: { ...query[id], value } });
      }
    }
  };
  useEffect(() => {
    if (globalState.queryFired) {
      let buildQuery = queryBuilder({ ...query });
      dispatch(appLoading(true));

      let body = {
        continuationToken: "",
        inputPublicNode: {
          isin: buildQuery?.isin?.value,
          productName: buildQuery?.productName?.value,
          issuerLEI: buildQuery?.issuerLEI?.value,
          issuerName: buildQuery?.issuerName?.value,
          lastUpdateDate: buildQuery?.lastUpdateDate,
          historicVersion: false,
        },
      };
      dispatch(queryBuild({ ...body }));

      axios
        .post(QUERY_CRITERIA, body,  {
          params: {
            page: 0,
            limit: 50,
          },
        })
        .then((res) => {
          dispatch(addList(res));
          dispatch(appLoading(false));
        })
        .catch((err) => {
          dispatch(error());
          dispatch(appLoading(false));
        });

      dispatch(toggleExpandView({ query: false, list: true }));
      dispatch(queryFired(false));
    }
  }, [globalState.queryFired]);

  function disabledFromDate(current) {
    let selectedFromDate = query.lastUpdateDate.range.to;
    return (
      moment(selectedFromDate, dateFormat) &&
      current >= moment(selectedFromDate, dateFormat)
    );
  }

  function disabledToDate(current) {
    let selectedToDate = query.lastUpdateDate.range.from;
    return (
      moment(selectedToDate, dateFormat) &&
      current <= moment(selectedToDate, dateFormat).endOf("day")
    );
  }

  useEffect(() => {
    dispatch(addQuery({ ...query }));
  }, [query]);

  useEffect(() => {
    if (
      JSON.stringify(globalState.queries) === JSON.stringify(DEFAULT_VALUES)
    ) {
      setQuery(DEFAULT_VALUES);
    }
  }, [globalState.queries]);

  return (
    <FormView title="Search Criteria">
      <StyledSearchCriteria container>
        <Grid item xs={6} container>
          <Grid item xs={4}>
            <Typography variant="h5">ISIN</Typography>
          </Grid>
          <Grid className="grid-flex" item xs={8}>
            <OperatorMenu operator="=" parent="isin" />

            <TextField
              className="text-field"
              placeholder="Enter"
              variant="outlined"
              id="isin"
              error={displayError.isin?.error}
              helperText={displayError.isin?.helperText}
              onChange={changeFieldValue}
              onBlur={changeFieldValue}
              value={query?.isin?.value}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Product Name</Typography>
          </Grid>
          <Grid className="grid-flex" item xs={8}>
            <OperatorMenu operator="=" parent="productName" />

            <TextField
              className="text-field"
              placeholder="Enter"
              variant="outlined"
              id="productName"
              error={displayError.productName?.error}
              helperText={displayError.productName?.helperText}
              onChange={changeFieldValue}
              onBlur={changeFieldValue}
              value={query?.productName?.value}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Issuer Name</Typography>
          </Grid>
          <Grid className="grid-flex" item xs={8}>
            <OperatorMenu operator="=" parent="issuerName" />

            <TextField
              id="issuerName"
              className="text-field"
              error={displayError.issuerName?.error}
              helperText={displayError.issuerName?.helperText}
              placeholder="Enter"
              variant="outlined"
              onChange={changeFieldValue}
              onBlur={changeFieldValue}
              value={query?.issuerName?.value}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} className="right-grid">
          <Grid item xs={4}>
            <Typography variant="h5">Issuer LEI</Typography>
          </Grid>
          <Grid className="grid-flex" item xs={8}>
            <OperatorMenu operator="=" parent="issuerLEI" />
            <TextField
              id="issuerLEI"
              className="text-field"
              error={displayError.issuerLEI?.error}
              helperText={displayError.issuerLEI?.helperText}
              placeholder="Enter"
              variant="outlined"
              onChange={changeFieldValue}
              onBlur={changeFieldValue}
              value={query?.issuerLEI?.value}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Selected Period</Typography>
          </Grid>
          {query?.lastUpdateDate?.operator === "[]" ? (
            <Grid item xs={8} className="grid-flex">
              <OperatorMenu
                setOperator={setOperator}
                operator={query?.lastUpdateDate?.operator}
                parent="lastUpdateDate"
              />
              <Grid container>
                <Grid item xs={1}>
                  <Typography className="elem-padding item-color" variant="h5">
                    From
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <DatePicker
                    format={dateFormatDisplay}
                    placeholder={dateFormatDisplay}
                    suffixIcon={<img src={calendarIcon} alt="calendar" />}
                    className="range-date-picker"
                    disabledDate={disabledFromDate}
                    onChange={(date) =>
                      changeFieldValue({
                        target: {
                          id: "lastUpdateDate",
                          value:
                            date !== null
                              ? moment(date).format(dateFormat)
                              : null,
                          name: "from",
                        },
                      })
                    }
                    value={
                      query?.lastUpdateDate?.range?.from
                        ? moment(query?.lastUpdateDate?.range?.from, dateFormat)
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="elem-padding item-color" variant="h5">
                    To
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <DatePicker
                    format={dateFormatDisplay}
                    suffixIcon={<img src={calendarIcon} alt="calendar" />}
                    placeholder={dateFormatDisplay}
                    // onChange={changeFieldValue}
                    className="range-date-picker"
                    disabledDate={disabledToDate}
                    value={
                      query?.lastUpdateDate?.range?.to
                        ? moment(query?.lastUpdateDate?.range?.to, dateFormat)
                        : null
                    }
                    onChange={(date) =>
                      changeFieldValue({
                        target: {
                          id: "lastUpdateDate",
                          value:
                            date !== null
                              ? moment(date).format(dateFormat)
                              : null,
                          name: "to",
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid className="grid-flex" item xs={8}>
              <OperatorMenu
                setOperator={setOperator}
                operator={query?.lastUpdateDate?.operator}
                parent="lastUpdateDate"
              />
              <DatePicker
                format={dateFormatDisplay}
                placeholder={dateFormatDisplay}
                suffixIcon={<img src={calendarIcon} alt="calendar" />}
                className="single-date-picker"
                // onChange={changeFieldValue}
                value={
                  query?.lastUpdateDate?.value
                    ? moment(query?.lastUpdateDate?.value, dateFormat)
                    : null
                }
                onChange={(date) =>
                  changeFieldValue({
                    target: {
                      id: "lastUpdateDate",
                      value:
                        date !== null ? moment(date).format(dateFormat) : null,
                    },
                  })
                }
              />
            </Grid>
          )}
        </Grid>
      </StyledSearchCriteria>
    </FormView>
  );
}

export default SearchCriteria;
